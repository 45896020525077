import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BookAnAppointmentMainComponent from '../BookAnAppointment/BookAnAppointment'
import emailjs from 'emailjs-com';

// Images
import ImgBanner from '../../Images/ImgBanner.jpeg'
import BeforeAndAfterImg from '../../Images/BeforeAndAfterImg.jpeg'
import rescleaning from '../../Images/rescleaning.webp'
import AirDuctCleaning from '../../Images/AirDuctCleaning.jpeg'
import hvaccleaning from '../../Images/hvaccleaning.png'
import readytocall from '../../Images/readytocall.jpeg'
import bookairduct from '../../Images/bookairduct.jpeg'

import './HomePage.scss';

const HomePage = () => {
    const [Menu, setMenu] = useState(false);
    const [BookAnAppointment, setBookAnAppointment] = useState(false);
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const [FormSubmited, setFormSubmited] = useState(false);
    const [CustInfo, setCustInfo] = useState({
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        Email: '',
        Service: 'Air Duct Cleaning | 99$',
        Date: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().substr(0, 10),
        Time: '8:00AM-11:00AM',
        Address1: '',
        Address2: '',
        City: '',
        State: 'AL',
        Zipcode: '',
        Note: ''
    });

    const location = useLocation().pathname
    const navigate = useNavigate()

    function sendEmail(e) {
        setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_h897je5', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                setFormSubmiting(false)
                setFormSubmited(true)
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className='HomePage'>
            <div className='Header'>
                <div className="HeaderBottom">
                    <div className="HeaderInfo">
                        <h1><span>SUPER </span>AIR DUCT</h1>
                    </div>
                    <div className="HeaderMenu">
                        <a href="tel:866-937-3719">866-937-3719</a>
                        {Menu ?
                            <img onClick={() => setMenu(false)} src="https://cdn-icons-png.flaticon.com/512/2976/2976286.png" alt="Air Duct Cleaning Near Me" />
                            :
                            <img onClick={() => { setMenu(true) }} src="https://cdn-icons-png.flaticon.com/512/2976/2976215.png" alt="Air Duct Cleaning Near Me" />
                        }
                    </div>
                    {
                        Menu ?
                            <div className="HeaderMainMenu">
                                <a onClick={() => setMenu(false)} href="#mainbanner" >Home</a>
                                <a onClick={() => setMenu(false)} href="#services" >Services</a>
                                <a onClick={() => setMenu(false)} href="#whyus" >About Us</a>
                                <a onClick={() => setMenu(false)} href="#footer" >Contact Us</a>
                                <div className="HeaderMainMenuMore">
                                    <a onClick={() => {
                                        setMenu(false)
                                        setBookAnAppointment(true)
                                    }}>Book An Appointment <img src="https://cdn-icons-png.flaticon.com/512/7322/7322265.png" alt="Book An Appointment" /></a>
                                    <a href="tel:866-937-3719">CALL NOW! <img src="https://cdn-icons-png.flaticon.com/512/597/597177.png" alt="call now" /></a>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
            <div className="WebSite">
                <div id="mainbanner" className="MainBanner">
                    <div className="MainBannerInfo">
                        <h1>
                            Super Air Duct Cleaning
                            <br />
                        </h1>
                        <h2>At SUPER AIR DUCT, We are proud to provide the local service with the best Air duct cleaning, Furnace Duct Cleaning, and Vent Cleaning Service. We have been providing these services for over 20 years.</h2>
                        {/* <h2>At Super Air Duct, we are proud to provide the local area {city && location.split('/')[2] ? city.FullName : city.State} with the best chimney, fireplace, and vent services. We have been providing these services for over 20 years.</h2> */}
                        <a href="tel:866-937-3719">866-937-3719</a>
                    </div>
                    <div className="MainBannerImg">
                        <div className="MainBannerImgDark"></div>
                        <img src={ImgBanner} alt="AIR DUCT CLEANING" />
                    </div>
                </div>
                <div id='services' className="Services">
                    <div className="Services1">
                        <img src={AirDuctCleaning} alt="AIR DUCT CLEANING" />
                        <h2>AIR DUCT CLEANING</h2>
                        <p>
                            We specialize in duct cleaning, duct and basement with state-of-the art equipment. Your system will run more efficiently After the cleaning is complete we will show you before and after pictures of your ducts so you can see the difference that we made. We clean all types of duct systems including but not limited too metal or flex duct work. We provide a certificate of quality assurance that states no harmful bacteria was found on your air ducts after our service call
                            <br /><br />
                            To ensure long-term efficiency and performance, it's important to maintain the indoor air quality of your HVAC system. Over time, these contaminants build up inside the ductwork, creating an ideal breeding ground for mold, bacteria, fungi and other microbes. Dirt, dust and other particles drawn into HVAC system ducts pass through fiberglass filters and infiltrate into equipment, undermining the performance and energy efficiency of these systems.
                            <br /><br />
                            As the <a href="https://www.epa.gov/indoor-air-quality-iaq/should-you-have-air-ducts-your-home-cleaned" target="_blank">EPA</a> points out, a build-up of only 0.42" of dirt on a heating or cooling coil can decrease efficiency by 21%. If this continues over time, it can lead to higher repair and maintenance costs, as well as shorter service life. Contact us today!
                        </p>
                    </div>
                    <div className="Services2">
                        <img src={rescleaning} alt="RESIDENTIAL AIR DUCT CLEANING" />
                        <h2>RESIDENTIAL AIR DUCT CLEANING</h2>
                        <p>
                            Super Air Duct is the best residential air duct cleaning service you will find anywhere. We have got the most impressive experts in this field, who can clean your vents and ensure the best quality air. To ensure that you get precisely the residential air duct cleaning service that you are looking for. And our prices are very reasonable!
                            <br /><br />
                            Are you looking for some help with your residential air duct cleaning? You will find it here at Super Air Duct. We are the best residential HVAC company that you can turn to in this town. With us, your needs will be met because we have trained professionals who have been working hard on providing excellent quality services. So go ahead and contact us today for residential air duct cleaning
                            <br /><br />
                            We’re here to help you with any HVAC issues. Call us at <a href="tel:866-937-3719">866-937-3719</a> and set up an appointment to get your questions answered or your problems solved.
                        </p>
                    </div>
                    <div className="Services3">
                        <img src={hvaccleaning} alt="HVAC CLEANING SERVICE" />
                        <h2>HVAC CLEANING SERVICE</h2>
                        <p>
                            For the best HVAC cleaning service, call Super Air Duct. Our team is available 24/7 to ensure that your HVAC is at its best. At Super Air Duct, we provide our customers with a quick and efficient service that always exceeds expectations!
                            <br /><br />
                            Super Air Duct is the most trusted name in the HVAC cleaning and repair industry. We provide top-notch repair and maintenance solutions to residential, commercial and industrial clients. Our years of experience have given us a profound understanding of how this field operates, which enables us to offer you the best services at competitive prices.
                            <br /><br />
                            Our HVAC duct cleaning service solutions are some of the most fascinating and well-known duct cleaning alternatives out there. All you have to do to acquire them is to contact us once. Then, we will immediately send a fully loaded team of experts to your position. Call and purchase any duct cleaning service you want right now! You are not going to regret it!</p>
                    </div>
                </div>
                <div className="ServicesCallNow">
                    <div className="ServicesCallNowInfo">

                        <h1>
                            Quality service is our specialty.
                            <br />
                            We are here to help you with all your needs and provide the very best in customer service.
                            <br />
                            Contact us today to get started!

                        </h1>
                        <a href={`tel:866-937-3719`}>CALL NOW !</a>
                    </div>
                    <div className="ServicesCallNowImg">
                        <div className="ServicesCallNowImgDark"></div>
                        <img src={readytocall} alt="Contact us today to get started!" />
                    </div>
                </div>
                <div className="ServicesMore">

                    <h1>MORE OF OUR SERVICES:</h1>
                    <div className="ServicesMoreBoxMain">
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Air Duct Cleaning" />
                            <h3>Air Duct Cleaning</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Air Duct Services" />
                            <h3>Air Duct Services</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Furnace Cleaning" />
                            <h3>Furnace Cleaning</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Air Duct Inspection" />
                            <h3>Air Duct Inspection</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Furnace Inspection" />
                            <h3>Furnace Inspection</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="HVAC Inspection" />
                            <h3>HVAC Inspection</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="HVAC Cleaning" />
                            <h3>HVAC Cleaning</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Residential Air Duct Cleaning" />
                            <h3>Residential Air Duct Cleaning</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Furnace Duct Cleaning" />
                            <h3>Furnace Duct Cleaning</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Vent Cleaning" />
                            <h3>Vent Cleaning</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Vent Cleaning Service" />
                            <h3>Vent Cleaning Service</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="AC Cleaning Service" />
                            <h3>AC Cleaning Service</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="AC Inspection Service" />
                            <h3>AC Inspection Service</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Air Duct Repair" />
                            <h3>Air Duct Repair</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Cleaning Ducts Services" />
                            <h3>Cleaning Ducts Services</h3>
                        </div>
                        <div className="ServicesMoreBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Cleaning Air Ducts" />
                            <h3>Cleaning Air Ducts</h3>
                        </div>
                    </div>
                </div>
                <div className="BeforeAndAfter">
                    <h1>Is it time to clean your air duct?</h1>
                    <img src={BeforeAndAfterImg} alt="Is it time to clean your air duct?" />
                    <h2>There are several factors that increase the risk of unclean ducts system. These include:</h2>
                    <h3>- If you don't maintain your ventilation system, the air ducts may accumulate dust particles and other debris. When you turn on the HVAC system, it recirculates the pollutants throughout the house, affecting your family's comfort and health.</h3>
                    <h3>- <a href="https://www.epa.gov/indoor-air-quality-iaq/should-you-have-air-ducts-your-home-cleaned" target="_blank">EPA</a> recommends that air ducts be cleaned only as necessary, as excessive cleaning can lead to the release of dust and dirt that can adversely affect indoor air quality, both in the home and workplace. <a href="https://www.epa.gov/indoor-air-quality-iaq/should-you-have-air-ducts-your-home-cleaned" target="_blank">EPA</a> also recommends that if you have a fuel burning furnace, stove or fireplace they be inspected for proper functioning and serviced before each heating season to protect against carbon monoxide poisoning.</h3>
                    <h3>- Unclean air ducts can cause a number of problems throughout your HVAC system including: Decreased efficiency leading to higher energy bills, Increased risk of a fire, Rusting effect on your heat exchanger and uneven heating or cooling. To avoid these problems, it's important to hire a professional duct cleaning company like us so that we can clean your air ducts thoroughly and safely.</h3>
                </div>
                <div className="BookAnAppointmentMain">
                    <div className="BookAnAppointmentMainLeft">
                        <h1>The professionals at Super Air Duct Cleaning are ready to help you</h1>
                        <p>
                            Looking for a reliable and friendly party to help with your Air Duct cleaning needs? SUPER Air Duct provides you with excellent service at a fair price. From cleaning, to repairing, or even installing new equipment, we have you covered. If you would like to learn more about our services please call today!
                            <br /> <br />
                            Keeping your ducts clean and in good working order helps prevent fires and prolongs the life of your air duct system. Trust the experts at Super Air Duct to provide you with reliable care and maintenance, so depend on us year after year.
                        </p>
                    </div>
                    <div className="BookAnAppointmentMainRight">
                        <div className="BookAnAppointmentMainRightInfo">
                            <h1>Book an Appointment Online Now...</h1>
                            <button onClick={() => setBookAnAppointment(true)}>BOOK NOW</button>
                        </div>

                    </div>
                    <div className="BookAnAppointmentMainRightImg">
                        <div className="MainBannerImgDark"></div>
                        <img src={bookairduct} alt="Book an Appointment Online Now..." />
                    </div>
                    {
                        BookAnAppointment ?
                            <BookAnAppointmentMainComponent setBookAnAppointment={setBookAnAppointment} CustInfo={CustInfo} />
                            :
                            null}
                </div>
                <div id='whyus' className="WhyUs">
                    {/* <img src="https://i.insider.com/61dca5f64ede320018b6e5a6?width=900&format=jpeg&auto=webp" alt="" /> */}
                    <div className="WhyUsTitle">
                        <h1>WHY CHOOSE US?</h1>
                        <p>Air duct cleaning is not something that you can do yourself. It requires extensive knowledge, experience and skill which is why we at Super Air Duct are here for your needs. Our professional and extremely experienced team will make sure that your ducts is cleaned thoroughly and professionally. We offer air duct cleaning services for all types of HVAC, Furnace and vents in the area. If you need anything done to your Ducts system then call us today to schedule an appointment if you need anything done to your air duct cleaning.</p>
                    </div>
                    <div className="WhyUsList">
                        <div className="WhyUsLkistBox">
                            <div className="WhyUsLkistBoxLeft">
                                <img src="https://cdn-icons-png.flaticon.com/512/2780/2780505.png" alt="Maintaining air ducts and furnaces is critical in ensuring a safe and comfortable home." />
                            </div>
                            <div className="WhyUsLkistBoxRight">
                                <h2>Maintaining air ducts and furnaces is critical in ensuring a safe and comfortable home.</h2>
                                <h3>Duct cleaning helps you avoid several problems, including health issues that can be caused by poor air quality and fire hazards, as well as damage to your property. By performing maintenance and cleaning regularly, you can prevent these problems from arising in the first place.</h3>
                            </div>
                        </div>
                        <div className="WhyUsLkistBox">
                            <div className="WhyUsLkistBoxLeft">
                                <img src="https://cdn-icons-png.flaticon.com/512/2212/2212838.png" alt="A air ducts and hvac inspection will help protect you, your loved ones and your investment." />
                            </div>
                            <div className="WhyUsLkistBoxRight">
                                <h2>A air ducts and hvac inspection will help protect you, your loved ones and your investment.</h2>
                                <h3>If you've been avoiding a air ducts inspection, now is the time to get your air ducts. Annual inspections are a modest investment that can help prevent fires and keep you and your family safe.</h3>
                            </div>
                        </div>
                        <div className="WhyUsLkistBox">
                            <div className="WhyUsLkistBoxLeft">
                                <img src="https://cdn-icons-png.flaticon.com/512/807/807303.png" alt="Air Ducts Repair and Cleaning" />
                            </div>
                            <div className="WhyUsLkistBoxRight">
                                <h2>Air Ducts Repair and Cleaning</h2>
                                <h3>We offer a complete range of restoration and installation services. Whether you need extensive repair work to your existing furnace or need to ducts it with a new one, we can help you efficiently restore your air duct system back to a beautiful state of repair.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='footer' className="Footer">
                <div className="FooterFormMain">
                    <div className="FooterFormTitle">
                        <h1>CONTACT US FOR MORE DETAILS</h1>
                    </div>
                    <div className="FooterForm">

                        <form className="contact-form" onSubmit={sendEmail}>
                            <h3>Name</h3>
                            <input type='text' name="Name" />
                            <h3>Phone</h3>
                            <input type='tel' name="Phone" />
                            <h3>Location (Address)</h3>
                            <input type='text' name="Location" />
                            <h3>Message</h3>
                            <input type='text' name="Message" />

                            <input type="hidden" name="Company_From" defaultValue={`Super Air Duct`} />

                            {
                                FormSubmiting ?
                                    <button class="buttonload">
                                        <i class="fa fa-refresh fa-spin"></i>Loading
                                    </button>
                                    :

                                    <input type="submit" value="SEND" />
                            }
                        </form>
                    </div>
                </div>
                <div className="FooterContactUs">
                    <div className="FooterContactUsTitle">
                        <h2>CONTACT DETAILS</h2>
                        <h3>We are always available to answer any questions you may have. Please feel free to contact us either via phone or email, or use our contact form below. You can also visit us personally -- directions are available on our website.</h3>
                    </div>
                    <div className="FooterContactUsInfo">
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/597/597177.png" alt="super air duct cleaning phone number" />
                            <h2>Phone Number: <a href={`tel:866-937-3719`}>866-937-3719</a></h2>
                        </div>
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/646/646135.png" alt="supet air duct email" />
                            <h2>Email: <a href="mailto:info@superairduct.com">info@superairduct.com</a></h2>
                        </div>
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/1006/1006771.png" alt="super air duct website" />
                            <h2>Website: <a href="https://www.superairduct.com/">www.SuperAirDuct.com</a></h2>
                        </div>
                    </div>
                    <div className="FooterContactUsAbout">
                        <h2>
                            Our Customers Rates: 5.0 Stars 
                        </h2>
                        <h3>
                            © Copyright 2022 Super Air Duct. All Right Reserved. <span onClick={() => navigate('/sitemap')}>Sitemap</span>
                        </h3>
                    </div>
                </div>

            </div>
            {
                !BookAnAppointment ?
                    <div className="CallNow">
                        {/* <h1>CALL NOW !</h1> */}
                        <a href="tel:866-937-3719">CALL NOW !</a>
                    </div>
                    :
                    null
            }
            {
                !BookAnAppointment ?
                    <div className="BookAnAppointmentButton" onClick={() => setBookAnAppointment(true)}>
                        <h1>BOOK ONLINE</h1>
                    </div>
                    :
                    null
            }
        </div>
    );
}

export default HomePage;
