import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ThankPage.scss';

const ThankPage = () => {
    const [Menu, setMenu] = useState(false);
    const navigate = useNavigate()
    return (
        <div className='ThankPage'>
            <div className='Header'>
                <div className="HeaderBottom">
                    <div className="HeaderInfo">
                        <h1 onClick={() => navigate(-1)}><span>SUPER </span>AIR DUCT</h1>
                    </div>
                    <div className="HeaderMenu">
                        <a href="tel:866-950-5723">866-950-5723</a>
                    </div>
                </div>
            </div>
            <div className='BookAnAppointmentMainComponentThanks'>
                <h3>Your appointment has been successfully created</h3>
                <h5 onClick={() => navigate(-1)}> Take me back to Home page</h5>
            </div>
        </div>
    );
}

export default ThankPage;
